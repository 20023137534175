@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;700&display=swap');

.loader-container {
  width: 75%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(ff, ff, ff, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/*
input[type="radio"] + span {
  transition: background .2s,
    transform .2s;
}

input[type="radio"] + span:hover{
transform: scale(1.2);
}

input[type="radio"]:checked + span {
background-color: #0254a4;
box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked{
 color: #0254a4;
} */
